<template>
  <div>
    <ModalDelete @deletar="handleDeleteConfime($event)" v-if="del" />
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />
      <div class="primary" :class="profile && profile.company_data ? '' : 'mt-none'">
        <div v-if="edite.active !== true" class="box table">
          <div class="header">
            <h1 class="header__title">Permissões da conta</h1>
            <button v-on:click="handleAdd()">Adicionar usuário</button>
          </div>

          <div class="painel">
            <b-table :items="getTable.users" :fields="fields">
              <template #cell(acao)="row">
                <EditUser @edite:user="handleEdite($event)" @delete:user="handleDelete($event)" :id="row.item.id" />
              </template>
            </b-table>
          </div>
        </div>
        <div v-else class="box table edite">
          <div class="box-inputs">
            <div class="field">
              <label>Nome</label>
              <input
                name="name"
                type="text"
                v-bind:value="name"
                v-on:input="name = $event.target.value"
                :disabled="edite.type === 'edite'"
              />
            </div>
            <div class="field">
              <label>E-mail</label>
              <input
                name="email"
                type="email"
                v-bind:value="email"
                v-on:input="email = $event.target.value"
                :disabled="edite.type === 'edite'"
              />
              <span v-if="edite.type === 'add'">O usuário será notificado por e-mail.</span>
            </div>
          </div>
          <div class="box-inputs-checkbox">
            <div class="header">
              <label>Permissões</label>
              <div v-on:click="handleEditeClose()" class="close">
                <img src="~@/assets/images/icones/close.svg" alt="close" />
              </div>
            </div>
            <div class="div-checkbox">
              <input type="checkbox" id="ler" name="ler" v-model="ler" disabled="disabled" />
              <label for="ler">Ler dados e gráficos</label>
            </div>
            <div class="div-checkbox">
              <input type="checkbox" id="editar" name="editar" v-model="editar" :disabled="edite.id == userId" />
              <label for="editar">Editar integrações</label>
            </div>
            <div class="div-checkbox">
              <input type="checkbox" id="gerenciar" name="gerenciar" v-model="gerenciar" :disabled="edite.id == userId" />
              <label for="gerenciar">Gerenciar usuários</label>
            </div>
          </div>
          <div class="box-btn">
            <button v-on:click="handleSave()">Salvar alterações</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import EditUser from "@/components/edit-user/EditUser.vue";
import ModalDelete from "@/components/modal-delete/ModalDelete.vue";

import { listaUsuarioCompany, criaUsuarioCompany, atualizaUsuarioCompany, apagarUsuarioCompany } from "@/api/company";

export default {
  components: {
    Breadcrumbs,
    Panel,
    EditUser,
    ModalDelete,
  },
  data() {
    return {
      regras: [],
      breadcrumbs: [],
      panel: [],
      location: "",
      seeinformation: false,
      seeupgrade: false,
      id: "",
      edite: {
        active: false,
        type: "",
      },
      del: false,
      name: "",
      email: "",
      ler: true,
      editar: false,
      gerenciar: false,
      data: {
        title: {
          top: "",
          name: "Permissões da conta",
        },
      },
      fields: [
        { key: "nome", label: "Nome" },
        { key: "email", label: "E-mail" },
        { key: "permissoes", label: "Permissões" },
        { key: "acao", label: "" },
      ],
    };
  },
  mounted() {
    this.fillData();
  },
  computed: {
    ...mapState({
      profile: state => state.user.dadosCompletos,
    }),
    getTable() {
      let res = this.$store.state.company.users;

      const users = [];

      res.map(user => {
        let permissoes = "Ler gráficos";

        if (user.manage_users === 0 && user.manage_integrations === 1) {
          permissoes = "Ler gráficos e editar integrações";
        }

        if (user.manage_users === 1 && user.manage_integrations === 1) {
          permissoes = "Ler gráficos, editar integrações e gerenciar usuários";
        }

        if (user.manage_users === 1 && user.manage_integrations === 0) {
          permissoes = "Ler gráficos e gerenciar usuários";
        }

        users.push({
          id: user.user_id,
          nome: user.name,
          email: user.email,
          permissoes,
          acao: "",
        });
      });

      return {
        title: {
          top: "",
          name: "Detalhamento",
        },
        users,
      };
    },
    getEdit: {
      get() {
        return this.$store.state.company.usersEdited;
      },
      set(newData) {
        return newData;
      },
    },
    userId() {
      let res = this.$store.state.user.dadosCompletos;
      return res.user_id;
    },
  },
  methods: {
    listaUsuarioCompany,
    criaUsuarioCompany,
    atualizaUsuarioCompany,
    apagarUsuarioCompany,
    fillData() {
      this.listaUsuarioCompany();
      this.location = "Usuários";
    },
    handleEditeClose() {
      this.edite = false;
    },
    handleEdite(id) {
      this.$store.state.company.usersEdited = true;

      this.edite = id;

      let res = this.$store.state.company.users;

      const user = res.find(user => user.user_id === id.id);

      this.name = user.name;
      this.email = user.email;
      this.editar = user.manage_integrations;
      this.gerenciar = user.manage_users;
    },
    handleDelete(id) {
      this.del = true;
      this.id = id;
    },
    handleDeleteConfime(status) {
      if (status) {
        apagarUsuarioCompany(this.id);
      }
      this.del = false;
      this.id = "";
    },
    handleAdd() {
      this.edite = {
        active: true,
        type: "add",
      };
      this.name = "";
      this.email = "";
      this.ler = true;
      this.editar = false;
      this.gerenciar = false;
    },
    handleSave() {
      // if (this.gerenciar === true) {
      //   this.editar = false;
      // }

      // if (this.editar === false) {
      //   this.gerenciar = false;
      // }

      if (this.edite.type === "add") {
        criaUsuarioCompany({
          email: this.email,
          username: this.email,
          name: this.name,
          manage_users: this.gerenciar,
          manage_integrations: this.editar,
        });
      }

      if (this.edite.type === "edite") {
        atualizaUsuarioCompany(this.edite.id, {
          email: this.email,
          username: this.email,
          name: this.name,
          manage_users: this.gerenciar,
          manage_integrations: this.editar,
        });
      }
    },
  },
  watch: {
    getEdit(data) {
      this.edite.active = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: 400;
  }
}
.primary {
  width: 100%;
  margin-top: 65px;
}
.setup {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}
.content {
  display: flex;
  align-items: flex-start;
}

.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 0 !important;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  &:nth-child(2) {
    margin-right: 0;
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow: hidden;
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    height: auto;
    max-width: 100%;
    width: 100%;
    &:after {
      content: "";
      display: block;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background-color: #41a7ff;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
    .painel {
      height: auto;
      overflow: inherit;
    }
  }
  &.edite {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .header {
      margin-bottom: 0;
    }
    .box-inputs {
      max-width: 50%;
      width: 100%;
      padding-right: 15px;
      &-checkbox {
        max-width: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 15px;
        padding-top: 0;
        padding-bottom: 55px;
        .header {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 5px;
          label {
            font-size: 16px;
            font-weight: 600;
            color: #2c2c2c;
          }
        }
      }
      .field {
        display: flex;
        justify-content: center;
        flex-direction: column;
        &:first-child {
          margin-bottom: 40px;
        }
        label {
          font-size: 16px;
          font-weight: 600;
          color: #2c2c2c;
          margin-bottom: 5px;
        }
        input {
          height: 50px;
          border: 1px solid #e6e6e6;
          & + span {
            margin-top: 10px;
          }
        }
        span {
          font-size: 14px;
          font-weight: 200;
        }
      }
    }
    .box-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      button {
        background-color: #73cb53;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .box-filter {
    justify-content: flex-end;
  }
  .box {
    max-width: 100%;
    margin-top: 30px;
    margin-right: 0;
    padding: 25px;
    &.table {
      margin-top: 30px;

      overflow: scroll;
      .box-inputs,
      .box-inputs-checkbox {
        max-width: 100%;
        padding: 0;
        order: 2;
      }
      .box-inputs-checkbox {
        margin-top: 15px;
        order: 1;
      }
      .box-btn {
        order: 3;
      }
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
