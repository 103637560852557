<template>
  <div class="btn-menu" v-on:click="handleOpenMenus()">
    <img src="~@/assets/images/icones/btn-edite.svg" alt="Download" />
    <div v-if="viewMenu" class="menu">
      <div
        v-if="!edit"
        @click="
          handleEdite({
            id,
            active: true,
            type: 'edite',
          })
        "
      >
        Editar
      </div>
      <div v-on:click="handleDelete(id)">Remover conta</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "edit"],
  data() {
    return {
      viewMenu: false,
    };
  },
  filters: {},
  mounted() {},
  methods: {
    handleOpenMenus() {
      this.viewMenu = !this.viewMenu;
    },
    handleEdite(id) {
      this.$emit("edite:user", id);
    },
    handleDelete(id) {
      this.$emit("delete:user", id);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.btn-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  cursor: pointer;
  .menu {
    position: absolute;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    top: 25px;
    z-index: 10;
    width: 145px;
    div {
      padding: 15px;
      transition: all 350ms ease-in-out;
      &:hover {
        background-color: #c2c2c2;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
}
</style>
