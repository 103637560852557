import client from "@/api";
import store from "@/store";
import {
  _rotaGetUsersMyCompany,
  _rotaAddUsersMyCompany,
  _rotaDelUsersMyCompany,
  _rotaPutUsersMyCompany,
} from "@/api/_caminhosApi";
import { buscarPerfil } from "@/api/login";

export function listaUsuarioCompany() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("company/RESET_USERS_COMPANY", null);
  client
    .get(`${_rotaGetUsersMyCompany()}`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("company/SET_USERS_COMPANY", resp.data.users);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function criaUsuarioCompany(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaAddUsersMyCompany()}`, data)
    .then(resp => {
      store.commit("company/ADD_USERS_COMPANY", resp.data.user);
      store.commit("company/SET_USERS_COMPANY_EDITED", false);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("erro", erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function atualizaUsuarioCompany(id, data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .put(`${_rotaPutUsersMyCompany(id)}`, data)
    .then(resp => {
      buscarPerfil();
      store.commit("company/PUT_USERS_COMPANY", resp.data.user);
      store.commit("company/SET_USERS_COMPANY_EDITED", false);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("erro", erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function apagarUsuarioCompany(id, data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .delete(`${_rotaDelUsersMyCompany(id)}`, data)
    .then(resp => {
      store.commit("company/DEL_USERS_COMPANY", id);
      store.commit("company/SET_USERS_COMPANY_EDITED", false);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
    })
    .catch(erro => {
      console.log("erro", erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
