<template>
  <div class="modalDel">
    <div class="box">
      <p>Confirmar exclusão?</p>
      <div>
        <button class="btn" v-on:click="handleCancelar">Cancelar</button>
        <button class="btn wanning" v-on:click="handleDeletar">
          Excluir usuário
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {
    handleCancelar() {
      this.$emit("deletar", false);
    },
    handleDeletar() {
      this.$emit("deletar", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.modalDel {
  background-color: #0003;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .box {
    max-width: 450px;
    width: 100%;
    background-color: #fff;
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      font-size: 20px;
      color: #2c2c2c;
      font-weight: 600;
      margin-bottom: 30px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        &.wanning {
          background-color: #fd0d1b;
          color: #fff;
          max-width: 225px;
          width: 100%;
          height: 50px;
        }
      }
    }
  }
}
</style>
